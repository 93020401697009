<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 30%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="交班人" prop="shiftman" style="width: 15%">
              <a-input v-model.trim="queryParams.shiftman" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="接班人" prop="takeoverman" style="width: 15%">
              <a-input v-model.trim="queryParams.takeoverman" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 12%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <!--            <a-button type="primary" @click="deleteBatch()">删除</a-button>-->
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500, y: 600 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="shifttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="takeovertime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="nextshifttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="shiftstatus" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'完成':'未完成'}}</a-tag>
          </span>
          <span slot="takeovernum" slot-scope="value,record">
            <span>{{record.takeovernum?patrolShifts[record.takeovernum-1].value:""}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button @click="exportFireRecord(value,record)" type="link">导出</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="exportModalVisible" title="导出" centered>
      <template slot="footer">
        <a-button @click="exportModalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="公司/项目" prop="monitorpointnum">
          <a-select v-model="formDatas.monitorpointnum" placeholder="请选择">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="导出时段" prop="queryInstallDate">
          <a-range-picker v-model="queryInstallDate"></a-range-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import {exportFireAlarmRecord, getShiftDataListByCondition} from "A/patrol";
import {patrolShifts} from "@/json/wlwhistory";
import deptselect from "@/mixins/deptselect";
import {getMonitorPointNameListByCondition} from "A/monitoring";
export default {
  name: "fireRecord",
  mixins: [deptselect,pagination],
  data() {
    return {
      moment,
      patrolShifts,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      userdepidCascaderSelected: [],
      eventOptions:[],
      shiftStatusList:[
        {"value":"未完成"},
        {"value":"完成"},
      ],
      queryParams:{
        monitorpointname:'',
        shiftman:'',
        takeoverman:'',
        shiftstatus:'',
        starttime:'',
        endtime:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '班次',
          dataIndex: 'takeovernum',
          key: 'takeovernum',
          ellipsis: true,
          scopedSlots: { customRender: 'takeovernum' }
        },
        // {
        //   title: '交班时间',
        //   dataIndex: 'shifttime',
        //   key: 'shifttime',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'shifttime' }
        // },
        {
          title: '本班接班时间',
          dataIndex: 'takeovertime',
          key: 'takeovertime',
          ellipsis: true,
          scopedSlots: { customRender: 'takeovertime' }
        },
        {
          title: '本班交班时间',
          dataIndex: 'nextshifttime',
          key: 'nextshifttime',
          ellipsis: true,
          scopedSlots: { customRender: 'nextshifttime' }
        },
        {
          title: '交班人',
          dataIndex: 'shiftman',
          key: 'shiftman',
          ellipsis: true,
        },
        // {
        //   title: '交班人账号',
        //   dataIndex: 'shiftaccount',
        //   key: 'shiftaccount',
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '接班人',
          dataIndex: 'takeoverman',
          key: 'takeoverman',
          ellipsis: true,
        },
        // {
        //   title: '接班人账号',
        //   dataIndex: 'takeoveraccount',
        //   key: 'takeoveraccount',
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '交接班状态',
          dataIndex: 'shiftstatus',
          key: 'shiftstatus',
          ellipsis: true,
          scopedSlots: { customRender: 'shiftstatus' }
        },
        {
          title: '消防记录表',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      exportModalVisible:false,
      yearOptions: [],
      formDatas:{
        monitorpointnum:'',
        starttime:'',
        endtime:'',
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择公司/项目'}],
      },
      monitorpointList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "消防值班报表"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.formDatas.starttime = start;
      this.formDatas.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData()
    this.initDeptOptionsAll();
    this.getYearOptions();
    this.getMonitorPointNameList();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getShiftDataListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.eventcode='';
      this.$refs.queryForm.resetFields();
      this.userdepidCascaderSelected=[]
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let shiftid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'shiftid', shiftid);

    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
    exportFireRecord(value,record){
      let params={
        shiftid:record.shiftid,
        monitorpointnum:record.monitorpointnum,
        eventtype:901,
        firealarm:'true',
        shifttime:record.shifttime,
        takeovertime:record.takeovertime
      }
      exportFireAlarmRecord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("导出成功")
        }
      })
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.confirm()
      }).catch(()=>{
      });
    },
    confirm() {
      let params = {...this.queryParams}
      this.showLoading();
      getShiftDataListByCondition(params).then((res) => {
        this.hideLoading();
        if (res && res.returncode == "1" && res.errormsg == "none") {
          this.$message.error("在所选筛选条件下无消防值班表")
        } else {
          for (let i = 0; i < res.item.length; i++) {
            this.exportFireRecord("", res.item[i])
          }
          this.exportModalVisible = false
        }
      }).catch(() => {
        this.hideLoading();
      })

    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=4; i>-1; i--) {
        this.yearOptions.push(currentYear-i+'');
      }
    },
  },
}
</script>
<style scoped>

</style>